.individual-page {
    margin-top: 0;
    padding-bottom: 4rem;
}

.individual-cont {
    margin: 0;
    width: 100vw;
    background-color: #6F0500ff;
}


/* ==============================MEDIA QUERIES (MD)===================================== */

@media screen and (max-width:1024px) {
    .individual-products-wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}


/* ==============================MEDIA QUERIES (SD)===================================== */

@media screen and (max-width:600px) {
    .individual-products-wrapper {
        grid-template-columns: 1fr;
        margin: 0;
    }
}

@media (min-width: 413px) and (max-width: 540px) {
    .individual-products-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width:601px) and (max-width: 768px) {
    .individual-products-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}