.patners {
    margin-top: 10em;
}

.slick-slider.slick-initialized {
    margin-top: 12em;
}

.patners-logo-card img {
    width: 50%;
}

.small-icon {
    width: 80% !important;
}

.slick-list {
    padding: 0 0 0 31px;
}

.slick-track {
    display: flex !important;
    align-items: center !important;
    left: 20px;
}

.slick-prev {
    left: 8px;
    z-index: 99;
}

.slick-next {
    right: 8px;
    z-index: 99;
}


/* .slick-prev,
.slick-next {
    border-radius: 100%;
    color: var(--blood-red) !important;
    background-color: var(--blood-red) !important;
} */


/* ==============================MEDIA QUERIES (MD)===================================== */

@media screen and (max-width:1024px) {}


/* ==============================MEDIA QUERIES (SD)===================================== */

@media screen and (max-width:600px) {
    .patners {
        margin-top: 6em;
    }
    .patners-carousel-heading {
        top: 45px;
        padding-bottom: 2rem;
    }
    .small-icon {
        width: 71% !important;
    }
    .patners-logo-card img {
        width: 71%;
        margin: 3em;
    }
}

@media screen and (max-width:412px) {
    .patners-logo-card img {
        margin: 3.8em !important;
    }
}

@media screen and (max-width:414px) {
    .patners-logo-card img {
        margin: 4em !important;
    }
}