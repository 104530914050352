.individual-products-carousel {
    padding-bottom: 6em;
}

.products-carousel-header {
    padding: 3rem;
}

.slick-slider.slick-initialized {
    margin-top: 0 !important;
}

.slick-next:before,
.slick-prev:before {
    color: #6F0500ff !important;
}


/* ==============================MEDIA QUERIES (MD)===================================== */

@media screen and (max-width:1024px) {}


/* ==============================MEDIA QUERIES (SD)===================================== */

@media screen and (max-width:600px) {
    .individual-page {
        padding-bottom: 0 !important;
    }
}