.product-page-header-main-cont {
    margin-top: 5rem;
    width: 100vw;
}

.product-page-header-cont {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: flex-end;
}

.product-page-header-image-cont img {
    width: 70vw;
    height: calc(100vh - 5rem);
    filter: grayscale(1);
    float: right;
}


/* ==============================MEDIA QUERIES (MD)===================================== */

@media screen and (max-width:1024px) {}


/* ==============================MEDIA QUERIES (SD)===================================== */

@media screen and (max-width:600px) {
    .product-page-header-main-cont {
        margin-top: 32em;
        margin-bottom: -28em;
    }
    .product-page-header-image-cont img {
        display: none;
    }
}