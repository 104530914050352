.corporate-products-carousel {
    padding-bottom: 6em;
}

.products-carousel-header {
    padding: 3rem;
}

.slick-slider.slick-initialized {
    margin-top: 0 !important;
}