@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300&display=swap');
.about {
    margin-top: 0!important;
    position: relative;
    bottom: 10rem;
}

.about-page-header .page-desc-wrapper p {
    font-size: 40px;
}

.about-container {
    z-index: 999;
}

.about-desc-wrapper {
    width: calc(100vw - 270px) !important;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.8099614845938375) 0%, rgba(255, 255, 255, 0.7651435574229692) 9%, rgba(255, 255, 255, 1) 100%);
}

.about-page-prop-left {
    position: absolute;
    left: 0;
    width: 135px;
    height: 10rem;
    background: rgba(128, 0, 128, 0.637);
}

.about-page-prop-right {
    position: absolute;
    right: 0;
    width: 135px;
    height: 10rem;
    background: rgba(249, 150, 29, 0.555);
}

.about-desc-1 {
    padding: 3rem;
}

.about-desc-1 h2,
article {
    font-family: 'Montserrat';
    font-weight: normal;
}

.about-desc-2 {
    margin-top: 3rem;
    padding: 0 10rem 6rem 3rem;
}

.about-desc-2 h5 {
    font-family: 'Montserrat';
}

.about-desc-2 li,
article,
p {
    font-family: 'Montserrat';
    font-weight: normal;
    list-style: circle !important;
}

.about-desc-2 li {
    margin-left: 3rem;
}


/* ==============================MEDIA QUERIES (MD)===================================== */

@media screen and (max-width:1024px) {
    .about-desc-wrapper {
        position: relative;
        left: 2em;
    }
}


/* ==============================MEDIA QUERIES (SD)===================================== */

@media screen and (max-width:414px) {
    .about-page-prop-left {
        display: none;
    }
    .about-page-prop-right {
        display: none;
    }
    .about-desc-wrapper {
        position: relative !important;
        left: -2em !important;
        margin: 0;
        padding: 0;
        width: 100vw !important;
    }
    .about-desc-1 {
        padding: none;
    }
    .about-desc-2 {
        width: 100vw !important;
        padding: 10px;
        text-align: left;
    }
    .about-desc-2 li {
        margin-left: 1em;
    }
}

@media (min-width:280px) and (max-width:320px) {
    .about {
        margin-top: 3rem !important;
        margin-bottom: -10em !important;
    }
}

@media (min-width:321px) and (max-width:360px) {
    .about {
        margin-top: 3rem !important;
        margin-bottom: -10em !important;
    }
}

@media (min-width:361px) and (max-width:375px) {
    .about {
        margin-top: 3rem !important;
        margin-bottom: -142px !important;
    }
}

@media (min-width:376px) and (max-width: 384px) {
    .about {
        margin-top: 3rem !important;
        margin-bottom: -128px !important;
    }
}

@media (min-width:385px) and (max-width: 412px) {
    .about {
        margin-top: 3rem !important;
        margin-bottom: -127px !important;
        right: 11px !important;
    }
}

@media (min-width:413px) and (max-width: 414px) {
    .about {
        margin-top: 3rem !important;
        margin-bottom: -136px !important;
        right: 11px !important;
    }
}

@media (min-width:415px) and (max-width: 540px) {
    .about {
        margin-bottom: -5em !important;
    }
    .about-desc-wrapper {
        margin-top: 5rem !important;
        left: -52px !important;
        width: 100vw !important;
    }
    .about-desc-1 {
        padding: 0;
    }
    .about-desc-2 {
        width: 100vw !important;
        padding: 10px;
        text-align: left;
        margin-top: 6rem;
    }
}

@media (min-width:601px) and (max-width:768px) {
    .about {
        margin-top: 3rem !important;
        margin-bottom: 80px !important;
    }
    .about-page-prop-left {
        display: none;
    }
    .about-page-prop-right {
        display: none;
    }
    .about-desc-wrapper {
        position: relative !important;
        top: 8em;
        left: -5em !important;
        margin: 0;
        padding: 0;
        width: 100vw !important;
    }
    .about-desc-1 {
        padding: 2rem;
    }
    .about-desc-2 {
        margin-top: 0;
        width: 100vw !important;
        padding: 1em;
        text-align: left;
    }
    .about-desc-2 li {
        margin-left: 1em;
    }
    .page-header-image-cont img {
        display: none;
    }
}