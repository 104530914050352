section.categoryBg {
    margin-top: 5rem !important;
}

.category-background {
    background: rgb(54, 3, 0);
    padding: 6em 0 3em 3em;
}

.category-bg-prop {
    background: rgba(255, 140, 0, 0.616);
    padding: 2rem;
    width: 30%;
    position: relative;
    top: 5rem;
    left: 0;
}

.category-bg-prop::after {
    content: " ";
    padding: 0;
    margin: 0;
    display: none !important;
}


/* ==============================MEDIA QUERIES (MD)===================================== */

@media screen and (max-width:1024px) {}


/* ==============================MEDIA QUERIES (SD)===================================== */

@media screen and (max-width:600px) {
    .category-bg-prop {
        width: 77%;
    }
}

@media screen and (max-width:360px) {
    .category-page {
        padding-bottom: 1rem !important;
    }
}

@media screen and (max-width: 412px) {
    .category-page {
        padding-bottom: 2rem !important;
    }
    .category-bg-prop {
        width: 84% !important;
    }
}

@media screen and (max-width: 414px) {
    .category-page {
        padding-bottom: 2rem !important;
    }
    .category-bg-prop {
        width: 84% !important;
    }
}