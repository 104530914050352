@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300&display=swap');
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
    font-family: 'Monserrat';
}

:root {
    --carrot-orange: #ED8F1Eff;
    --carrot-orange-2: #F9971Dff;
    --white: #FFFFFFff;
    --blood-red: #6F0500ff;
    --black: #000000ff;
    --purple: 160;
    --color-gray-100: hsl(var(--primary-hue), 47%, 94%);
    --color-gray-200: hsl(var(--primary-hue), 26%, 75%);
    --color-gray-300: hsl(var(--primary-hue), 24%, 64%);
    --color-gray-400: hsl(var(--primary-hue), 16%, 41%);
    --color-gray-500: hsl(0, 0%, 24%);
    --color-gray-600: hsl(var(--primary-hue), 48%, 15%);
    --container-width-lg: 80%;
    --container-width-md: 90%;
    --transition: all 500ms ease;
}


/* General styles */

html {
    scroll-behavior: smooth;
    background: rgba(41, 2, 0, 0.082);
}

body {
    font-family: 'Monserrat', 'san-serif';
    font-weight: 100;
    color: #000;
    line-height: 1.7;
    overflow-x: hidden;
}

.container {
    width: var(--container-width-lg);
    max-width: 1920px;
    margin-inline: auto;
}

h1,
h2,
h3,
h4,
h5 {
    line-height: 1.2;
    color: var(--color-gray-100);
    font-family: 'Monserrat';
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: 2rem;
}

a {
    color: var(--color-black);
    font-family: 'Monserrat';
}

img {
    object-fit: cover;
    display: block;
    width: 100%;
}

.btn {
    color: #fff;
    font-family: 'Montserrat';
    font-weight: normal;
    width: fit-content;
    margin: 0 auto;
    padding: 0.9rem 2rem;
    background: var(--blood-red);
    transition: var(--transition);
}

.btn:hover {
    color: #fff;
    background: var(--carrot-orange-2);
}

.btn .lg {
    padding: 1.2rem;
    font-size: 1.1rem;
}

.btn .sm {
    padding: 0.4rem 1.2rem;
    font-size: 0.9rem;
}

.btn .sm:hover {
    background: var(--carrot-orange-2);
}

section {
    margin-top: 10rem;
    font-family: 'Monserrat';
}

.section_head {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-family: 'Monserrat';
}

.section_head span {
    background: var(--color-gray-500);
    padding: 0.8rem;
    border-radius: 1rem;
    color: var(--color-secondary);
    font-size: 1.5rem;
}

.card {
    background: white;
    text-align: center;
    padding: 3rem 2rem;
    border-radius: 1.5rem 1.5rem 1rem 1rem;
    transition: var(--transition);
    border: 1px solid var(--carrot-orange-2) !important;
    font-family: 'Monserrat';
}

.card span {
    width: 3rem;
    height: 3rem;
    background: var(--color-golden-yellow);
    color: var(--color-gray-100);
    font-size: 1.5rem;
    padding: 0.8rem;
    display: grid;
    place-items: center;
    margin-bottom: 1.5rem;
    margin-inline: auto;
    border-radius: 1rem;
    transition: var(--transition);
    font-family: 'Monserrat';
}

.card small {
    margin-top: 1rem;
    display: block;
    font-weight: 300;
    font-family: 'Monserrat';
}


/* ===============================HEADE STYLES===================================== */

.header {
    margin-top: 5rem;
    height: 20rem;
    overflow: hidden;
    font-family: 'Monserrat', 'san-serif';
}

.header-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    place-items: center;
    background: transparent;
    font-family: 'Monserrat', 'san-serif';
}

.header_container_bg {
    position: absolute;
    object-fit: contain;
    font-family: 'Monserrat', 'san-serif';
}

.header_container_bg img {
    transform: translateY(0em);
    opacity: 0.5;
    object-fit: contain;
}

.header_content {
    position: relative;
    width: 44%;
    margin: 0 auto;
    text-align: center;
    color: var(--color-gray-100);
    font-family: 'Monserrat', 'san-serif';
}

.header_container h2 {
    margin-bottom: 1rem;
}

.header_content p {
    color: rgba(255, 255, 255, 0.7);
    font-family: 'Monserrat', 'san-serif';
}


/* ===============================PAGE HEADER===================================== */

.page-header-main-cont {
    margin-top: 5rem !important;
    width: 100vw;
}

.page-header-cont {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
}

.page-header-image-cont img {
    width: 100vw;
    height: calc(100vh - 5rem);
    filter: grayscale(1);
    float: right;
}


/* ===============================PRODUCTS HEADER===================================== */

.products-header-main-cont {
    margin-top: 5rem !important;
    width: 100vw;
}

.products-header-cont {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: flex-end;
}

.products-header-image-cont img {
    width: 77vw;
    height: 66vh;
    filter: grayscale(1);
    float: right;
}

.products-header-prop {
    position: relative;
    top: 1rem;
    background: var(--carrot-orange-2);
    width: 3rem;
    height: 18rem;
}

.products-header-desc {
    display: flex;
    align-items: center;
}

.products-desc-wrapper p,
h2 {
    font-family: 'Montserrat';
}

.products-desc-wrapper:first-child {
    display: block;
    margin: 3rem;
    width: max-content;
}


/* ==============================MEDIA QUERIES (MD)===================================== */

@media screen and (max-width:1024px) {}


/* ==============================MEDIA QUERIES (SD)===================================== */

@media screen and (max-width:600px) {
    /* page header */
    .page-header-main-cont {
        display: none;
    }
    /* products page header */
    .products-header-cont {
        grid-template-columns: 1fr;
        justify-items: center;
    }
    .products-header-prop {
        display: none;
    }
    .products-header-image-cont {
        display: none;
    }
}