/* =========================PRODUCTS=========================== */

.bs-products {
    margin-top: 6rem;
}

.bs-products-container {
    width: 100vw;
}

.individual-products-wrapper {
    background: transparent;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 2rem;
}

.bs-product-card {
    display: flex;
    flex-direction: column;
    margin: 1rem 1rem;
    filter: drop-shadow(2px 3px 1px rgba(0, 26, 111, 0.178));
    padding: 0;
    border: none !important;
}

.bs-product-image {
    filter: grayscale(1);
    border-radius: 1rem 1rem 0 0;
}

.bs-product-details {
    padding: 1.6rem;
    height: 17em;
}

.bs-product-card h4,
p {
    font-family: 'Montserrat';
    font-weight: normal;
    margin-top: 1rem;
}

.bs-product-card h4 {
    font-weight: bold;
}

.bs-products-home-btn {
    position: relative;
    top: 2rem;
    border-radius: 5rem;
}