.main-product-page-cont {
    height: calc(100vh + 27rem);
}

.product-page {
    margin-top: 0;
    position: relative;
    bottom: 18em;
}

.product-cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.product-details-forms-main-cont {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}

.product-details-desc-cont {
    width: 43vw;
    padding: 3rem;
    background: linear-gradient(180deg, rgba(198, 198, 198, 0.773546918767507) 0%, rgba(198, 198, 198, 0.6558998599439776) 10%, rgba(198, 198, 198, 1) 100%);
}

.products-form-cont {
    background: #a3a3a3;
    border-radius: 5px;
    width: 43vw;
    height: fit-content;
    padding: 3rem;
}

.products-form-cont h2 {
    padding-bottom: 1rem;
}

.product-form {
    display: flex;
    flex-direction: column;
}

.product-form input {
    margin-bottom: 1em;
    padding: 1em;
    width: 90%;
    background: transparent;
    border: 1px solid #696969;
    border-radius: 5px;
}

.product-form textarea {
    padding: 5px;
    width: 90%;
}

.consent-items input {
    display: inline-block !important;
    width: min-content;
}

.product-page-btn {
    margin-top: 1em;
}


/* ==============================MEDIA QUERIES (MD)===================================== */

@media screen and (max-width:1024px) {
    .main-product-page-cont {
        height: calc(100vh + 40rem);
    }
}


/* ==============================MEDIA QUERIES (SD)===================================== */

@media screen and (max-width:600px) {
    .product-page {
        margin-top: 18em;
    }
    .product-details-forms-main-cont {
        grid-template-columns: 1fr;
    }
    .product-details-desc-cont {
        width: 100vw;
        padding: 3em 2em 3em 2em;
    }
    .products-form-cont {
        width: 100vw;
        padding: 3em 2em 3em 2em;
    }
    .product-form input {
        width: 99%;
    }
    .product-form textarea {
        width: 99%;
    }
}


/* ==============================MEDIA QUERIES (SD)===================================== */

@media (min-width: 280px) and (max-width: 320px) {
    .main-product-page-cont {
        height: calc(100vh + 40rem);
    }
}

@media (min-width: 321px) and (max-width: 360px) {
    .main-product-page-cont {
        height: calc(100vh + 40rem);
    }
}

@media (min-width: 361px) and (max-width: 375px) {
    .main-product-page-cont {
        height: calc(100vh + 37rem);
    }
}

@media (min-width: 376px) and (max-width:384px) {
    .main-product-page-cont {
        height: calc(100vh + 37rem);
    }
}

@media (min-width:385px) and (max-width: 412px) {
    .main-product-page-cont {
        height: calc(100vh + 16rem);
    }
}

@media (min-width: 413px) and (max-width: 414px) {
    .main-product-page-cont {
        height: calc(100vh + 16rem);
    }
}

@media (min-width: 415px) and (max-width: 540px) {
    .main-product-page-cont {
        height: calc(100vh + 21rem);
    }
}