.category-page {
    margin-top: 0;
    padding-bottom: 6rem;
}

.category-cont {
    margin: 0;
    width: 100vw;
    background-color: #fff;
}

.category-desc {
    padding: 2rem 3rem 2rem 3rem;
    background-color: #fff;
}

.category-links {
    padding: 2rem 0 1rem 0;
}

.category-header {
    padding: 1rem 0 1rem 0;
}

.category-links span {
    margin: 5px;
}

.category-links a {
    font-size: 16px !important;
    font-weight: lighter !important;
}

.category-links a:hover {
    color: var(--carrot-orange) !important;
}

@media screen and (max-width:375px) {
    .category-page {
        padding-bottom: 2rem !important;
    }
}

@media screen and (max-width:384px) {
    .category-page {
        padding-bottom: 2rem !important;
    }
}