.icon {
    width: 4%;
    background-size: 100%;
    position: fixed;
    right: 16px;
    bottom: 10px;
    z-index: 25;
    transform: rotateY(180deg);
}


/* ==============================MEDIA QUERY=============================== */

@media screen and (max-width:1080px) {
    .icon {
        width: 10%;
    }
}

@media screen and (max-width:600px) {
    .icon {
        width: 12%;
    }
}