    section.contact {
        margin: 0;
    }
    
    .contact-cont {
        margin: 0;
        padding: 0;
    }
    
    .cont-detail-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        height: 45em;
    }
    
    .detail-left {
        background: linear-gradient(180deg, rgba(249, 151, 29, 0.742734593837535) 0%, rgba(249, 151, 29, 0.6250875350140056) 14%, rgba(249, 151, 29, 1) 100%);
        width: 50vw;
        padding: 3rem 3rem 2rem 3rem;
        position: relative;
        top: -20em;
    }
    
    .detail-right {
        background: linear-gradient(180deg, rgba(111, 5, 0, 0.6923144257703081) 0%, rgba(111, 5, 0, 0.700717787114846) 10%, rgba(111, 5, 0, 1) 100%);
        width: 50vw;
        padding: 3rem 3rem 2rem 3rem;
        position: relative;
        top: -20em;
        color: #fff;
    }
    
    .form {
        margin-top: 7em;
    }
    
    .form form {
        display: flex;
        flex-direction: column;
    }
    
    .form form input {
        padding: 1rem;
    }
    
    .form form textarea {
        padding: 5px 0 0 16px;
    }
    
    .form form input,
    textarea {
        border: #6F0500ff solid 1px;
        background: transparent;
    }
    
    .form form input,
    textarea,
    .contact_form_btn {
        margin-top: 2rem;
        border-radius: 5px;
    }
    
    .map {
        margin: 2rem 0 0 0;
    }
    
    .map iframe {
        width: 49vw;
        position: relative;
        top: 1em;
        right: 49px;
    }
    /* ==============================MEDIA QUERIES (MD)===================================== */
    
    @media screen and (max-width:1024px) {}
    /* ==============================MEDIA QUERIES (SD)===================================== */
    
    @media screen and (max-width:600px) {
        section.contact {
            margin-bottom: 75em;
        }
        .cont-detail-wrapper {
            grid-template-columns: 1fr;
        }
        .detail-left {
            width: 100vw;
            top: 5em;
            padding: 1em 1em 8em 1em;
        }
        .detail-right {
            width: 100vw;
            top: 0;
        }
        .map-header {
            padding: 0;
        }
        .form {
            margin-top: 1em;
        }
        .map iframe {
            width: 84vw;
            position: relative;
            left: -24px;
        }
    }
    
    @media screen and (max-width:360px) {
        .map iframe {
            left: -19px !important;
        }
    }
    
    @media screen and (max-width: 375px) {
        .map iframe {
            left: -19px !important;
        }
    }
    
    @media screen and (max-width: 384px) {
        section.contact {
            margin-bottom: 72em;
        }
        .map iframe {
            left: -20px !important;
        }
    }
    
    @media screen and (max-width:412px) {
        section.contact {
            margin-bottom: 71em !important;
        }
        .map iframe {
            left: -17px !important;
        }
    }
    
    @media screen and (max-width:414px) {
        section.contact {
            margin-bottom: 70em !important;
        }
        .map iframe {
            left: -17px !important;
        }
    }
    
    @media screen and (max-width:540px) {
        section.contact {
            margin-bottom: 69em;
        }
        .map iframe {
            left: -6px;
        }
    }
    
    @media (min-width:601x) and (max-width:768px) {
        .container.contact-cont {
            margin-top: 20em;
        }
    }