/* =========================PRODUCTS=========================== */

.product-category-container {
    width: 100vw;
    padding-bottom: 4rem;
}

.product-category-wrapper {
    background: transparent;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 2rem;
}


/* ==============================MEDIA QUERIES (MD)===================================== */

@media screen and (max-width:1024px) {
    .product-category-wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}


/* ==============================MEDIA QUERIES (SD)===================================== */

@media screen and (max-width:414px) {
    .product-category-wrapper {
        grid-template-columns: 1fr;
        margin: 0;
    }
}

@media (min-width: 415px) and (max-width: 540px) {
    .product-category-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
    .category-page {
        padding-bottom: 3rem;
    }
}

@media (min-width:601px) and (max-width: 768px) {
    .product-category-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}