@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300&display=swap');
nav {
    height: 5rem;
    width: 100vw;
    background: #fff;
    display: grid;
    place-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    filter: drop-shadow(2px 3px 1px rgba(111, 6, 0, 0.178));
}


/* only shows on md and sm screens */

.nav_toggle_btn {
    display: none;
}

.nav_container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.logo {
    width: 12rem;
    display: block;
    position: relative;
    right: 3em;
}

.nav_links {
    display: flex;
    gap: 3.5rem;
    align-items: center;
}

.nav_links a {
    color: var(--black);
    transition: var(--transition);
    font-family: 'Montserrat';
    font-weight: normal;
}

.nav_links a:hover {
    color: var(--color-golden-yellow);
}

.active_nav {
    position: relative;
}

.active_nav::after {
    content: '';
    display: block;
    width: -webkit-fill-available;
    height: 5em;
    background: rgba(111, 6, 0, 0.116);
    position: absolute;
    bottom: -26px;
}


/* MEDIA QUERIES */

@media screen and (max-width:1024px) {
    .nav_toggle_btn {
        display: inline-block;
        background: transparent;
        font-size: 1.8rem;
        cursor: pointer;
    }
    .nav_toggle_btn svg {
        color: var(--color-gray-100)
    }
    .nav_links {
        position: absolute;
        top: 100%;
        right: 0;
        flex-direction: column;
        gap: 0;
    }
    .active_nav,
    .active_nav::after {
        display: none;
    }
    .nav_links li {
        height: 4rem;
        width: 100%;
        box-shadow: -2rem 2rem 5rem rgba(0, 0, 0, 0.4);
        animation: navAnimation 600ms ease forwards;
        transform: rotateX(90deg);
        opacity: 0;
        transform-origin: top;
    }
    .nav_links li:nth-child(2) {
        animation-delay: 200ms;
    }
    .nav_links li:nth-child(3) {
        animation-delay: 400ms;
    }
    .nav_links li:nth-child(4) {
        animation-delay: 600ms;
    }
    .nav_links li:nth-child(5) {
        animation-delay: 800ms;
    }
    .nav_links li:nth-child(6) {
        animation-delay: 1s;
    }
    .nav_links li:nth-child(7) {
        animation-delay: 1.2s;
    }
    @keyframes navAnimation {
        to {
            transform: rotateX(0);
            opacity: 1;
        }
    }
    .nav_links li a {
        background-color: var(--color-primary);
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 5rem 1rem 3rem;
    }
    .show_nav {
        display: flex;
        background: #6F0500;
        padding: 1em;
    }
    .show_nav a {
        color: #fff;
        padding: 1em 3em 1em 3em;
    }
    .show_nav a:hover {
        color: #ED8F1E;
    }
    .hide_nav {
        display: none;
    }
}


/* ==============================MEDIA QUERIES (SD)===================================== */

@media screen and (max-width:600px) {
    /* navbar */
    .logo {
        right: 1em;
    }
    .nav_links {
        right: -35px;
    }
}