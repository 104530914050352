@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300&display=swap');

/* ==============================HEADER============================ */

.main-header {
    width: 100vw;
    height: calc(90vh - 5rem);
    display: grid;
    place-items: center;
    margin-top: 5em;
    margin-right: 0;
}

.main-header-container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: flex-end;
    gap: 3rem;
}

.main-header-left {
    position: relative;
    right: 6rem;
}

.main-header-left h1,
h4,
h3 {
    font-family: 'Montserrat';
    color: #000;
}

.main-header-left h4 {
    margin-top: 1rem;
    font-size: 23px;
    font-weight: normal;
}

.header-btn {
    position: relative;
    top: 3rem;
}

.main-header-right {
    position: absolute;
    right: 0;
}

.main-header-image {
    position: relative;
    right: 0;
}

.main-header-image img {
    width: 50vw;
    filter: grayscale(1);
    height: calc(90vh - 5rem) !important;
}

.header-styling-prop {
    background: rgba(249, 150, 29, 0.384);
    width: 1rem;
    height: 13em;
    float: left;
}


/* =======================SECTION HEADER============================ */

.section-heading {
    font-family: 'Montserrat';
    font-weight: normal;
    font-size: 25px;
    display: flex;
    justify-content: center;
}

.section-header {
    position: relative;
    top: 6rem;
}

.patners-carousel-heading {
    padding-bottom: 12rem;
}


/* ==============================HOME CTA=========================== */

.cta-home {
    width: 100vw;
    height: 10vh;
    margin-top: 0;
    display: grid;
    place-items: center;
    background: var(--carrot-orange);
    color: #000;
}

.cta-home-conainer h5 {
    font-size: 20px;
    text-align: center;
    word-spacing: 3px;
    letter-spacing: 1px;
    font-family: 'Montserrat';
    font-weight: 600;
}


/* =========================PRODUCTS=========================== */

.products {
    margin-top: 6rem;
}

.products-container {
    width: 100vw;
}

.products-wrapper {
    background: transparent;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 2rem;
}


/* add the flex wrap for devices under 1180px */

.product-card {
    display: flex;
    flex-direction: column;
    margin: 1rem 1rem;
    filter: drop-shadow(2px 3px 1px rgba(0, 26, 111, 0.178));
    padding: 0;
    border: none !important;
}

.product-image {
    filter: grayscale(1);
}

.product-details {
    padding: 1.6rem;
    height: 17em;
}

.product-card h4,
p {
    font-family: 'Montserrat';
    font-weight: normal;
    margin-top: 1rem;
}

.product-card h4 {
    font-weight: bold;
}

.products-home-btn {
    position: relative;
    top: 2rem;
    border-radius: 5rem;
}


/* ==============================FAQS=========================== */

.faqs {
    padding-bottom: 10em;
}

.faqs-wrapper {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 5rem;
    align-items: flex-start;
}

.faq-item {
    background-color: rgba(249, 150, 29, 0.384);
    padding: 2rem;
    cursor: pointer;
}

.faq-item div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-button {
    background: transparent;
    color: var(--color-gray-100);
    font-size: 1.5rem;
}

.faq-item h4 {
    font-family: 'Montserrat';
    font-weight: 500;
}

.faq-item p {
    margin-top: 1.5rem;
    font-family: 'Montserrat';
}


/* ==============================FOOTER=========================== */

footer {
    background: var(--color-secondary);
    margin-top: 0;
    font-size: 0.9rem;
    color: var(--color-black);
}

.footer_container {
    display: grid;
    grid-template-columns: 20rem 1fr 2fr 1fr;
    gap: 6rem;
}

a.footer_logo img {
    width: 55%;
}

a.logo.footer_logo img {
    width: 20%;
}

.footer_container article {
    font-family: 'Monserrat';
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
}

.footer_container article p {
    font-family: 'Montserrat';
    margin-top: 0.5rem;
    font-weight: normal;
}

.footer_container article h4 {
    font-family: 'Montserrat';
    margin-bottom: 0.6rem;
    font-size: 1rem;
    color: black;
}

.footer_links {
    display: flex;
    flex-direction: column;
}

.footer_links .permalink {
    font-family: 'Montserrat';
    font-weight: normal;
    padding: 0.5rem 0;
    color: var(--color-black);
}

.footer_contact_list li {
    font-family: 'Montserrat';
    font-weight: normal;
    padding: 0.25rem 0;
}

.footer_socials {
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.footer_socials a {
    font-family: 'Monserrat';
    background: #fff;
    padding: 0.5rem;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    transition: var(--transition);
    display: flex;
    margin: 0 auto;
}

.footer_socials a svg {
    color: rgb(0, 0, 0);
}

.footer_socials a:hover {
    border-color: black;
    background: transparent;
}

.footer_copyright {
    background: transparent;
    color: var(--color-gray-100);
    text-align: center;
    padding: 1.5rem 0;
    margin-top: 1rem;
}

.footer_copyright small {
    font-family: 'Montserrat';
    font-weight: normal;
}

.footer_contact_page_button {
    padding: 10px 3em;
    background: linear-gradient(90deg, rgba(40, 0, 88, 1) 0%, rgba(0, 0, 0, 1) 26%);
    color: #fff;
    border-radius: 5px;
    width: 11em;
    filter: drop-shadow(1px 3px 1px #000);
    font-family: 'Montserrat', sans-serif;
    width: 12em;
}

.footer_contact_page_button:hover {
    filter: none;
    box-shadow: 1px 3px 1px #000;
    background: transparent;
    border: #000 1px solid;
    color: #000;
}

.footer-date {
    font-family: 'Montserrat';
    font-weight: bold;
}


/* ==============================MEDIA QUERIES (MD)===================================== */

@media screen and (max-width:1024px) {
    /* nav */
    .show_nav {
        background: #6F0500;
        padding: 1em;
    }
    .show_nav a {
        color: #fff;
        padding: 1em 3em 1em 3em;
    }
    .show_nav a:hover {
        color: #ED8F1E;
    }
    /* main header */
    .main-header {
        padding: 0 6em 0 6em;
    }
    /* products */
    .products-wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
    /* Footer */
    .footer_container {
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
        justify-items: center;
    }
}


/* ==============================MEDIA QUERIES (SD)===================================== */

@media screen and (max-width:414px) {
    /* main header */
    .main-header {
        padding: 0;
    }
    .main-header-container {
        display: block;
        width: 100vw;
    }
    .main-header-left {
        left: 0;
        padding: 3em 1em;
    }
    .main-header-right {
        display: none;
    }
    .header-btn {
        top: 2rem;
    }
    /* products */
    .products {
        margin-top: 2rem;
    }
    .products-wrapper {
        grid-template-columns: 1fr;
        margin: 0;
    }
    /* faqs */
    .faqs {
        padding-bottom: 0;
    }
    .faqs-wrapper {
        grid-template-columns: 1fr;
        width: 96vw;
        margin: 0;
    }
    .faqs-container {
        margin-inline: 0em;
        padding: 0 5px 0 5px;
    }
    /* Footer */
    footer {
        margin-top: 3rem;
    }
    a.footer_logo {
        display: flex;
        justify-content: center;
    }
    .footer_container article {
        justify-self: center;
    }
    .footer_container {
        grid-template-columns: 1fr;
        gap: 3rem;
    }
    .footer_container article {
        align-items: center;
    }
    .footer_container p {
        text-align: center;
    }
    .footer_contact_list {
        text-align: center;
    }
}

@media (min-width: 280px) and (max-width: 320px) {
    .faqs-wrapper {
        width: 97vw !important;
        padding-left: 0;
    }
}

@media (min-width: 321px) and (max-width: 360px) {
    .faqs-wrapper {
        width: 97vw !important;
    }
}

@media (min-width: 361px) and (max-width: 375px) {
    .faqs-wrapper {
        width: 97vw !important;
    }
}

@media (min-width: 376px) and (max-width: 384px) {
    .faqs-wrapper {
        width: 97vw !important;
    }
}

@media (min-width: 385px) and (max-width: 412px) {
    .faqs-wrapper {
        width: 97vw !important;
    }
}

@media (min-width: 413px) and (max-width: 414px) {
    .faqs-wrapper {
        width: 97vw !important;
    }
}

@media (min-width: 415px) and (max-width: 540px) {
    /* main header */
    .main-header {
        padding: 0;
    }
    .main-header-container {
        display: block;
        width: 100vw;
    }
    .main-header-left {
        left: 0;
        padding: 3em 1em;
    }
    .main-header-right {
        display: none;
    }
    .header-btn {
        top: 2rem;
    }
    /* products */
    .products {
        margin-top: 3rem;
    }
    .products-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
    /* faqs */
    .faqs-wrapper {
        padding-left: 0;
        grid-template-columns: 1fr;
    }
}

@media (min-width:601px) and (max-width: 768px) {
    /* products */
    .products-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
    /* faqs */
    .faqs-wrapper {
        grid-template-columns: 1fr;
    }
}